import styled from 'styled-components';

export const Button = styled.button<{ big: boolean }>`
  background: rgba(255, 255, 255, 0.2);
  border: none;
  display: inline-flex;
  align-items: center;
  margin: 5px;
  font-size: ${(props) => (props.big ? '1.2rem' : '1rem')};
  color: white;
  padding: ${(props) => (props.big ? '0.35rem 1.6rem' : '0.25rem 1.5rem')};
  border-radius: 8px;
  &:hover {
    background: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.75rem;
    fill: white;
  }
`;
