const transitions = {
  normal: '0.1s',
};

const fontSize = {
  small: '0.9rem',
  big: '2.2rem',
};

export { transitions, fontSize };
